import { globalConfig } from './globalConfig';

const language = globalConfig.language || 'en';

const nautilusRoutes = {
    /**
     * Pages under Nautilus projects
     *
     * All routes here needs to physically exist on portal BE,
     * since they need pass through the currently active
     * studio/performer id.
     */
    holidayAwards: `/${language}/bestof/holiday-awards`,
    myAccounts: `/${language}/my-accounts`,
    scheduledShows: `/${language}/scheduled-shows`,
    settings: `/${language}/settings`,
    performance: `/${language}/performance/traffic-score`,
    servicesAgreementUpdate: `/${language}/services-agreement-update`,
    statisticsPayingMembersNotes: (memberId: number) => `/${language}/statistics/paying-members/${memberId}/notes`,
};

export default nautilusRoutes;
