import { defineTracking } from 'utils/googleAnalytics/defineTracking';

const category = 'dashboard page';

export default {
    ...defineTracking({
        loginSuccess: {
            category,
            action: 'login',
            label: 'login success',
            description: 'This event is triggered after user logs in.',
        },
    }),
};
