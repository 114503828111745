/* eslint-disable camelcase */

import riot from 'riot';
import $ from 'jquery';

import Config from '../../lib/chaos/Config';
import { Broadcaster } from '../../lib/chaos/Broadcaster';
import Chaos from '../../lib/chaos/Chaos';
import Notification from '../Notification/Notification';
import { isLiveJasmin } from 'configs/sites';

riot.tag('form-mixin-linked', '', function() {
	this.mixin('form');

	this.on('mount', function() {
		setTimeout(() => this.doHandler(true));
	});

	this.doHandler = function(isOnMount) {
		if (this.parent.opts.linked) {
			let value = this.parent.getValue();
			let linked = this.parent.opts.linked.split('|');
			if (this.parent.type !== 'radio' && this.prevValue === value) {return}
			this.prevValue = value;
			linked.forEach(method => {
				let handler = this['handler_' + method];
				if (handler) {
					handler.call(this, isOnMount === true);
				}
			});
		}
	};

	// Global submit on change
	this.handler_submitOnChange = function(isOnMount) {
		if (!isOnMount) {
			this.parent.form.trigger('submit');
		}
	};

	this.handler_isAllPrivacyRulesAccepted = function(isOnMount) {
		if (!isOnMount) {
			const inputs = this.parent.form.getInputStorage(),
				checkboxes = inputs.acceptRules,
				proceedLink = document.querySelector('.proceed-container a');

			if (checkboxes) {
				let isAllChecked = checkboxes.every(item => item.getValue() === true);
				isAllChecked && proceedLink.classList.remove('disabled');
				!isAllChecked && proceedLink.classList.add('disabled');
			}
		}
	};

	this.handler_myContentPriceSettingsChange = function(isOnMount) {
		if (isOnMount) {
			return;
		}
		const form = this.parent.form;
		const cb = function({ success }) {
			if (success) {
				Notification.getInstance().showNotification({
					text            : Chaos.translate('You have successfully changed your price.'),
					icon            : 'check-0',
					direction       : 'bottom',
					autoHideEnabled : true,
					closingEnabled  : false,
					hideTimeOut     : 5000
				});
			}
			form.off(form.CONST.FORM_AJAX_ALWAYS, cb);
		};
		this.handler_submitOnChange(isOnMount);
		form.on(form.CONST.FORM_AJAX_ALWAYS, cb);
	};

	this.handler_fireChaosEvent = function(isOnMount) {
		if (!isOnMount) {
			Broadcaster.fireEvent('statistics-period-change');
		}
	};

	// WebMoney - has account 'YES' selected, purse Id should be appear
	this.handler_webmoneyPurseId = function() {
		var purseEl = this.form.getInput('purseId');
		var submitEl = $(this.form.tags['form-submit'].root);
		var formEl = $(this.form.root);
		var submitRowEl = submitEl.closest('ph-row');
		var onNoParagraphs = formEl.nextAll('.onNo');

		switch (this.parent.getValue()) {
			case 'yes':
				onNoParagraphs.slideUp();
				if (!submitRowEl.is(':visible')) {
					submitRowEl.slideDown();
				}
				purseEl.enableRowValidation();
				purseEl.showRow();
				break;
			case 'no':
				if (!onNoParagraphs.first().is(':visible')) {
					onNoParagraphs.slideDown();
				}
				submitRowEl.slideUp();
				purseEl.hideRow();
				purseEl.disableRowValidation();
				break;
			default:
				onNoParagraphs.hide();
				break;
		}
	};

	// Cosmo - has account 'YES' selected, SAN field should be appear
	this.handler_cosmoSAN = function() {
		const sanEl = this.form.getInput('SAN');
		const submitEl = $(this.form.tags['form-submit'].root);
		const formEl = $(this.form.root);
		const submitRowEl = submitEl.closest('ph-row');
		const onNoParagraphs = formEl.nextAll('.onNo');

		switch (this.parent.getValue()) {
			case 'yes':
				onNoParagraphs.slideUp();
				if (!submitRowEl.is(':visible')) {
					submitRowEl.slideDown();
				}
				sanEl.enableRowValidation();
				sanEl.showRow();
				break;
			case 'no':
				if (!onNoParagraphs.first().is(':visible')) {
					onNoParagraphs.slideDown();
				}
				submitRowEl.slideUp();
				sanEl.hideRow();
				sanEl.disableRowValidation();
				break;
			default:
				onNoParagraphs.hide();
				break;
		}
	};

	// Crypto payment agreement checkbox change
	this.handler_cryptoAgreementChange = function() {
		if (this.parent.getValue()) {
			this.form.tags['form-submit'].enable()
		}
		else {
			this.form.tags['form-submit'].disable()
		}
	};

	this.handler_faceVisibilityOptionSelected = function() {
		if (this.parent.getValue()) {
			this.form.tags['form-submit'].enable();
		}
	};

	// ID has no expiry date checkbox handler
	this.handler_idNoExpiryCheckbox = function() {
		var el = this.form.getInput('month');
		if (this.parent.getValue()) {
			el.hideRow();
			el.disableRowValidation();
		}
		else {
			el.enableRowValidation();
			el.showRow();
		}
	};

	// Show hide state based on selected country
	this.handler_state = function() {
		var el = this.form.getInput('state');
		if (this.parent.getValue() !== 'US') {
			el.hideRow();
			el.disableRowValidation();
		}
		else {
			el.enableRowValidation();
			el.showRow();
			this.parent.form.hideEachValidation();
		}
	};

	// Show hide phoneNumber based on selected country
	this.handler_phoneNumber = function() {
		let countriesShowingPhoneNumberField = [
			'US',	// United State
			'CA'	// Canada
		];

		let el = this.form.getInput('phoneNumber');
		let selectedCountryCode = this.parent.getValue();

		if (countriesShowingPhoneNumberField.includes(selectedCountryCode)) {
			el.enableRowValidation();
			el.showRow();
		}
		else {
			el.hideRow();
			el.disableRowValidation();
		}
	};

	// Show hide referredBy field based on selected nationality
	this.handler_referredBy = function() {
		var el = this.form.getInput('referrer');
		if (!el) return;

		let nationalitiesDisablingReferredByField = [
			'46',	// Colombian
			'129',	// Moldovan
			'177',	// Romanian
			'179',	// Russian
			'215'	// Ukrainian
		];
		let selectedNationalityCode = this.parent.getValue();

		if (!selectedNationalityCode || nationalitiesDisablingReferredByField.includes(selectedNationalityCode)) {
			el.hideRow();
			el.disableRowValidation();
		}
		else {
			el.enableRowValidation();
			el.showRow();
			this.parent.form.hideEachValidation();
		}
	};

	// Revalidate IdNumber at Id type change
	this.handler_idTypeChange = function() {
		var input = this.form.getInput('idNumber');

		if (input.getValue()) {
			input.blur({
				target : input
			});
		}
	};

	this.handler_attachCategoryToScreenNameField = function() {
		var screenNameInput = this.form.getInput('screenName');
		var mainCategoryId = $('input[name="mainCategory"]:checked', this.form.root).val();
		this.prevValue = '';
		screenNameInput.scenario = { mainCategoryId : mainCategoryId };
	};

	this.handler_checkScreenNameOnCategoryChange = function() {
		var screenName = this.form.getInput('screenName');
		var mainCategoryId = $('input[name="mainCategory"]:checked', this.form.root).val();
		screenName.lastValidationValue = '';
		this.prevValue = '';
		screenName.change({
			target : { type : 'radio' }
		});
		screenName.scenario = { mainCategoryId : mainCategoryId };
	};

	this.handler_suggestScreenName_onValidationEnd = (hasError, input, hasGlobalError, validator) => {
		// When screen-name-check is failed
		if (
			hasError
			&& validator
			&& validator.Rule
			&& validator.Rule.rule
			&& typeof validator.Rule.rule === 'string'
			&& (/check-screen-name$/).test(validator.Rule.rule)
		) {
			this.parent.trigger('screen-name-taken');
		}
	};

	this.form.on(this.form.CONST.END_VALIDATION_EVENT, this.handler_suggestScreenName_onValidationEnd.bind(this));

	this.handler_suggestScreenNameOnLoad = function() {
		var screenName = this.form.getInput('screenName');
		screenName.lastValidationValue = '';
		screenName.blur();
	};

	// Category dropdown updates at performance type step
	this.handler_nameAndCategoryUpdate = function(isOnMount) {
		if (isOnMount || !isLiveJasmin) {return}

		var objectKey;
		var subSelectName;
		var subEl;
		var subcategories = Config.get('categorySelector').subCategoriesByCategoryType;

		switch (this.parent.opts.name) {
			case 'hotFlirtCategoryType':
				objectKey = 'hot_flirt';
				subSelectName = 'hotFlirtSubCategory';
				break;
			case 'nudeCategoryType':
				objectKey = 'nudity';
				subSelectName = 'nudeSubCategory';
				break;
			case 'nonNudeCategoryType':
				objectKey = 'non_nudity';
				subSelectName = 'nonNudeSubCategory';
				break;
			default:
				break;
		}
		subcategories = subcategories[objectKey];
		subcategories = subcategories[this.parent.getValue()];
		subEl = this.form.getInput(subSelectName);
		subEl.tags['form-mixin-datatable'].reload(subcategories);
	};

	this.handler_supportTopicSelect = (isOnMount) => {
		if (!isOnMount) {
			window.location = this.parent.opts.url;
		}
	};

	this.parent.on('change', this.doHandler.bind(this));
});
