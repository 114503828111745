/* eslint-disable complexity */

export default async function overlayLoader(overlayId) {
	let controller;
	switch (overlayId) {
		case 'snapshooter':
			controller = await import('./Snapshot/Snapshot');
			break;
		case 'confirmOverlayContent':
			controller = await import('./Confirm/ConfirmController');
			break;
		case 'memberProfile':
			controller = await import('./MemberProfile/MemberProfile');
			break;
		case 'MigrationFinishPerformerProcess':
			controller = await import('./FinishMigration/PerformerProcess');
			break;
		case 'migrationFinishProcess':
			controller = await import('./FinishMigration/Process');
			break;
		case 'memberNotes':
			controller = await import('./MemberNotes/MemberNotes');
			break;
		case 'sendNewMessage':
			controller = await import('./SendNewMessage/SendNewMessage');
			break;
		case 'consentBlock':
			controller = await import('./ConsentMandatoryOverlay/ConsentMandatoryOverlay');
			break;
		case 'accountSettingsContent':
			controller = await import('./AccountSettings/AccountSettings');
			break;
		case 'savePayoutPayoneerContent':
			controller = await import('./Payout/Payoneer');
			break;
		case 'savePayoutEpayserviceContent':
			controller = await import('./Payout/Epayservice');
			break;
		case 'savePayoutPaxumContent':
			controller = await import('./Payout/Paxum');
			break;
		case 'savePayoutSkrillContent':
			controller = await import('./Payout/Skrill');
			break;
		case 'savePayoutPayPalContent':
			controller = await import('./Payout/PayPal');
			break;
		case 'savePayoutDirectaContent':
			controller = await import('./Payout/Directa');
			break;
		case 'cropOverlay':
			controller = await import('./Crop/Crop');
			break;
		case 'selectIndividual':
			controller = await import('./SelectIndividual/SelectIndividual');
			break;
		case 'videoPlayerOverlayContainer':
			controller = await import('./VideoPlayer/VideoPlayer');
			break;
		case 'payoutOptionsOverlay':
			controller = await import('./PayoutOptions/PayoutOptionsOverlay');
			break;
		case 'DownloadAppGuideOverlay':
			controller = await import('./DownloadAppGuide/DownloadAppGuide');
			break;
		case 'displayNameChange':
			controller = await import('./DisplayNameChange/DisplayNameChange');
			break;
		case 'promotionPeriodReset':
			controller = await import('./PromotionPeriodReset/PromotionPeriodReset');
			break;
		case 'confirmEmailAddressOverlay':
			controller = await import('../Page/Authentication/SignupFlow');
			break;

		// Not real controllers
		case 'termsAndConditionsOverlay':
		case 'cookiePolicyOverlay':
		case 'privacyPolicyOverlay':
		case 'softwareLicenceAgreementOverlay':
			await import('./ServicesAgreement/ServicesAgreement');
			break;
		case 'newModelContent':
			await import('./ScreenNameAndCategory/ScreenNameAndCategory');
			controller = await import('../Page/Authentication/SignupFlow');
			break;
		case 'markAsPromoVideo':
			await import('./MarkAsPromoVideo/MarkAsPromoVideo');
			break;
		case 'promoVideoDetailsOverlay':
			await import('../Page/PromoVideo/PromoVideo');
			break;
		case 'goOnlineNotification':
			await import('./GoOnline/GoOnlineNotification');
			break;
		case 'immediatePayoutConfirmOverlay':
			await import('./ImmediatePayout/ImmediatePayoutConfirm');
			break;
	}

	return controller ? controller.default : false;
}
