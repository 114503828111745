import { SyncStorage } from './index';

const storage: SyncStorage = {
    get<T>(key: string): T | undefined {
        const itemStr = sessionStorage.getItem(key);

        if (!itemStr) {
            return undefined;
        }

        try {
            return (JSON.parse(itemStr) as T) || undefined;
        } catch (e) {
            console.warn(e);
        }
    },
    set(key, value) {
        try {
            sessionStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
        } catch (e) {
            console.warn(e);
        }
    },
    remove(key) {
        try {
            sessionStorage.removeItem(key);
        } catch (e) {
            console.warn(e);
        }
    },
    clear() {
        try {
            sessionStorage.clear();
        } catch (e) {
            console.warn(e);
        }
    },
};

export default storage;
